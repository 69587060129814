import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSubscribeConfirm } from '@app/store/slices/authSlice';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useParams } from 'react-router';
import { ConfirmEmailData } from '@app/api/auth.api';

interface ConfirmEmailFormData {
  token: string | undefined;
}

export const SubscribeConfirmForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token } = useParams();

  const subscribeConfirm = (values: ConfirmEmailFormData) => {
    dispatch(doSubscribeConfirm(values as ConfirmEmailData))
      .unwrap()
      .then(() => {
        navigate('/');
        notificationController.success({
          message: t('subscribeConfirm.successMessage'),
          description: t('subscribeConfirm.successDescription'),
        });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        navigate('/');
        //setLoading(false);
      });
  };

  useEffect(() => {
    subscribeConfirm({ token: token });
  });

  return <Auth.FormWrapper></Auth.FormWrapper>;
};
