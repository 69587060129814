import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { SubscribeConfirmForm } from '@app/components/auth/NewPasswordForm/SubscribeConfirmForm';

const SubscribeConfirmPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.subscribeConfirm')}</PageTitle>
      <SubscribeConfirmForm />
    </>
  );
};

export default SubscribeConfirmPage;
